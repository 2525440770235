<template>
    <div id="margin-dialog-body" class="ma-3">
        <v-dialog v-model="dialogConfirmacao" max-width="500">
            <v-card>
                <v-card-title class="headline primary--text"
                    >Deseja realmente confirmar a Remessa?</v-card-title
                >

                <v-card-text v-show="!dialogResponse"
                    >Ao confirmar a remessa você concorda com o encaminhamento
                    dos títulos á protesto na próxima janela de
                    envio!</v-card-text
                >
                <v-card-text v-show="dialogResponse"
                    >Remessa de títulos confirmada com sucesso!</v-card-text
                >

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="green darken-1"
                        text
                        v-show="!dialogResponse"
                        @click="aprovacaoRemessabtn"
                        data-cy="confirmarRemessa"
                        >Sim</v-btn
                    >

                    <v-btn
                        color="red darken-1"
                        text
                        v-show="!dialogResponse"
                        @click="fechaConfirmacao"
                        >Não</v-btn
                    >

                    <v-btn
                        color="green darken-1"
                        text
                        v-show="dialogResponse"
                        @click="fechaConfirmacao"
                        >Ok!</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogCancelamentoArquivo" max-width="500">
            <v-card>
                <v-card-title class="headline primary--text"
                    >Deseja realmente cancelar a Remessa?</v-card-title
                >

                <!---<v-card-text
                    v-show="!dialogResponseCancelamentoArquivo"
                >Ao confirmar a remessa você concorda com o encaminhamento dos títulos á protesto na próxima janela de envio!</v-card-text>
                <v-card-text v-show="dialogResponseCancelamentoArquivo">Remessa de títulos confirmada com sucesso!</v-card-text>--->

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="green darken-1"
                        text
                        v-show="!dialogResponseCancelamentoArquivo"
                        @click="cancelaArquivobtn"
                        data-cy="confirmarCancelamento"
                        >Sim</v-btn
                    >

                    <v-btn
                        color="red darken-1"
                        text
                        v-show="!dialogResponseCancelamentoArquivo"
                        @click="fechaCancelamento"
                        >Não</v-btn
                    >

                    <v-btn
                        color="green darken-1"
                        text
                        v-show="dialogResponseCancelamentoArquivo"
                        @click="fechaCancelamento"
                        >Ok!</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Snackbar
            v-if="dialogMensagemErro"
            :mostrarSnackbar="dialogMensagemErro"
            :corSnackbar="snackbarColor"
            :mensagemSnackbar="mensagemErro"
            @fecharSnackbar="dialogMensagemErro = false"
        />

        <v-dialog
            persistent
            transition="dialog-bottom-transition"
            v-model="loading"
            width="450px"
        >
            <div class="text-center" width="420px">
                <v-card>
                    <v-progress-circular
                        class="ma-12"
                        indeterminate
                        size="150"
                        color="primary"
                        >Carregando...</v-progress-circular
                    >
                </v-card>
            </div>
        </v-dialog>

        <div class="" align="left">
            <v-btn
                :disabled="ativaDownload"
                @click="downloadRespostaRemessapre"
                class="my-2"
                width="100%"
                dense
                small
                color="primary"
            >
                Resultado
                <v-icon class="ma-1">cloud_download</v-icon>
            </v-btn>
            <v-btn
                :disabled="ativaConfirmacao || disable"
                @click="aprovacaoConfirmacao"
                class="my-2"
                dense
                small
                width="100%"
                color="success"
                :data-cy="`confirmarUpload${index}`"
            >
                Confirmar
                <v-icon class="ma-1">done</v-icon>
            </v-btn>
            <v-btn
                :disabled="ativaCancelamentoArquivo"
                @click="cancelarArquivo"
                class="my-2"
                dense
                small
                width="100%"
                color="error"
                :data-cy="`cancelarUpload${index}`"
            >
                Cancelar
                <v-icon class="ma-1">cancel</v-icon>
            </v-btn>
        </div>
        <div class="white--text">
            <v-textarea
                label="Descrição do Erro"
                filled
                rows="5"
                class="grey lighten-2 mt-2"
                readonly
                color="black"
                v-show="existeErro"
                v-model="ds_erro_remessa"
            ></v-textarea>
        </div>
        <Snackbar
            v-if="mostrarSnackbar"
            :mostrarSnackbar="mostrarSnackbar"
            :timeoutSnackbar="6000"
            :corSnackbar="corSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="mostrarSnackbar = false"
        />
    </div>
</template>

<script>
import historicoRemessaService from '@/services/historicoRemessaService';
import Snackbar from '@/components/Snackbar';
import Vue from 'vue';

export default {
    name: 'UploadTituloDetalhes',
    props: {
        detalhe_remessa: {
            type: Object
        },
        index: Number,
        showActionSalvar: Boolean
    },
    components: {
        Snackbar
    },
    computed: {
        ativaConfirmacao() {
            if (
                this.detalhe_remessa.st_arquivo == 'P' &&
                this.detalhe_remessa.confirmacao == 1 &&
                this.detalhe_remessa.tp_origem == 1 && // só se pode confirmar o que vem do excel(tp_origem = 1)
                this.detalhe_remessa.titulos_processados >
                    this.detalhe_remessa.titulos_irregulares &&
                this.detalhe_remessa.titulos_processados <= 3000
            ) {
                return false;
            }
            return true;
        },
        ativaCancelamentoArquivo() {
            if (
                this.detalhe_remessa.st_arquivo == 'C' && //confirmado
                this.detalhe_remessa.tp_origem == 1 // só se pode cancelar o que vem do excel(tp_origem = 1)
            ) {
                return false;
            }
            return true;
        },
        ativaDownload() {
            if (['P', 'C', 'F'].includes(this.detalhe_remessa.st_arquivo)) {
                return false;
            }
            return true;
        },
        existeErro() {
            if (this.detalhe_remessa.cd_erro == true) {
                return true;
            }
            return false;
        }
    },
    methods: {
        fechaConfirmacao() {
            this.dialogConfirmacao = false;
        },
        fechaCancelamento() {
            this.dialogCancelamentoArquivo = false;
        },
        aprovacaoConfirmacao() {
            this.dialogConfirmacao = true;
            this.dialogResponse = false;
            this.detalhe_remessa.st_arquivo == 'P';
        },
        cancelarArquivo() {
            if (
                this.detalhe_remessa.contador_de_titulos_evoluidos &&
                Number(this.detalhe_remessa.contador_de_titulos_evoluidos) > 0
            ) {
                this.exibirSnackbar(
                    'red',
                    'Esta remessa possui um ou mais títulos que já evoluíram e não pode ser cancelada.'
                );
            } else {
                this.dialogCancelamentoArquivo = true;
                this.dialogResponseCancelamentoArquivo = false;
            }
        },
        exibirSnackbar(corSnackbar, mensagemSnackbar) {
            this.mostrarSnackbar = true;
            this.corSnackbar = corSnackbar;
            this.mensagemSnackbar = mensagemSnackbar;
        },
        aprovacaoRemessabtn() {
            this.dialogConfirmacao = false;
            this.loading = true;
            let service = new historicoRemessaService(Vue.http, this.$store);
            this.aprovacaoRemessaPromise = service
                .confirmaRemessa(this.detalhe_remessa.id_arquivo)
                .then(
                    response => {
                        this.historico = response.body;
                        this.loading = false;
                        this.disable = true;
                        this.$emit('reload', true);
                        return this.historico;
                    },
                    error => {
                        this.mensagem = error;
                        this.loading = false;
                        console.log(error);
                    }
                );
        },
        cancelaArquivobtn() {
            this.dialogCancelamentoArquivo = false;
            this.loading = true;
            let service = new historicoRemessaService(Vue.http, this.$store);
            this.cancelamentoArquivoPromise = service
                .cancelaArquivo(this.detalhe_remessa.id_arquivo)
                .then(
                    response => {
                        this.mensagemErro = response.body['status'];
                        this.dialogMensagemErro = true;
                        this.loading = false;
                        this.$emit('reload', true);
                        //return this.historico;
                    },
                    error => {
                        this.mensagemErro = error.body.erro;
                        this.dialogMensagemErro = true;
                        this.loading = false;
                    }
                );
        },
        downloadRespostaRemessapre() {
            this.loading = true;

            let service = new historicoRemessaService(Vue.http, this.$store);
            this.aprovacaoRemessaPromise = service
                .buscaResultados(this.detalhe_remessa.id_arquivo)
                .then(
                    response => {
                        this.historico = response.body;
                        let url;
                        if (
                            this.detalhe_remessa.tp_origem_descricao === 'API'
                        ) {
                            url = window.URL.createObjectURL(
                                new Blob([response.body], {
                                    type: 'application/json'
                                })
                            );
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'resultados.json');
                            document.body.appendChild(link);
                            link.click();
                        } else {
                            url = window.URL.createObjectURL(
                                new Blob([response.body], {
                                    type: 'application/vnd.ms-excel'
                                })
                            );
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'resultados.xlsx');
                            document.body.appendChild(link);
                            link.click();
                        }
                        this.loading = false;
                    },
                    error => {
                        this.mensagem = error;
                        this.loading = false;
                        console.log(error);
                    }
                );
        }
    },
    mounted() {
        if (
            this.detalhe_remessa.ds_erro ===
            'O limite maximo de titulos por arquivo é 3 mil'
        ) {
            this.ds_erro_remessa =
                'Erro: Número de títulos excede a quantidade permitida para upload via XLSX, recomendamos que faça a quebra do arquivo XLS em duas partes. O limite de títulos para upload através de um arquivo XLSX é de até 3 mil títulos (3000). Para envios de maior volume, indicamos o uso da nossa API.';
        } else {
            this.ds_erro_remessa = this.detalhe_remessa.ds_erro;
        }
    },
    data: () => ({
        mostrarSnackbar: false,
        corSnackbar: '',
        mensagemSnackbar: '',
        ds_erro_remessa: null,
        st_aprovacao: null,
        loading: false,
        disable: false,
        dialogResponse: false,
        dialogConfirmacao: false,
        dialogCancelamentoArquivo: false,
        dialogMensagemErro: false,
        mensagemErro: '',
        dialogResponseCancelamentoArquivo: false
    })
};
</script>
